.def_wrap_hide {
    display: none;
}

.def_wrap_show {
    flex-direction: column;
    border-top: none;
    display: flex;
    width: 100%;
    height: 100%;
}

.text_wrap {
    background-color: #4298b4;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 30px;
    display: flex;
    width: 100%;
}

.text_wrap h1 {
    padding-bottom: 20px;
    line-height: 1.5;
    font-size: 1.5em;
    color: white;
}

.text_wrap p {
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    line-height: 1.5;
    margin-top: 0px;
    color: white;
}

.def_img {
    width: 100%;
}