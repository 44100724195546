.test_wrap_hide {
    display: none;
}

.test_wrap_show {
    flex-direction: column;
    display: flex;
    height: 100%;
    width: 100%;
}

.discript_wrap {
    background-color: #33a474;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 30px;
    display: flex;
    width: 100%;
}

.discript_wrap h1 {
    margin-bottom: 0px;
    margin-top: 0px;
    color: white;
}

.discript_wrap p {
    padding-bottom: 30px;
    margin-bottom: 0px;
    padding-top: 10px;
    margin-top: 0px;
    color: white;
}

.discript_wrap hr {
    color: #eeeff1;
    width: 90%;
}

.how_wrap {
    height: 100%;
    width: 100%;
}

.how {
    padding-bottom: 20px;
    margin-right: 30px;
    margin-left: 30px;
}

.how_inner {
    background-color: hsla(0,0%,100%,.8);
    border-radius: 10px;
    align-items: center;
    flex-direction: row;
    display: flex;
}

.how_inner img {
    padding-left: 10px;
    height: 30px;
    width: 30px;
}

.how_inner p {
    padding-bottom: 15px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 15px;
    color: #576071;
    text-align: left;
    font-weight: 600;
    font-size: .8em;
}

.div_bottom_2 {
    padding-bottom: 10px;
    width: 100%;
}

.quest_div {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.quest_end_wrap {
    justify-content: center;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
}

.quest_end_btn {
    padding: 20px 70px 20px 70px;
    background-color: #6c4d7b;
    border-radius: 50px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    color: white;
}

.progress_wrap {
    background-color: #576071;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    display: flex;
    bottom: 0;
    right: 0;
    left: 0;
}

@media (min-width: 500px) {
    .progress_wrap {
        left: calc((100vw - 500px)/2);
        width: 500px;
    }
}

.percentage {
    color: #fff;
    flex: 0 0 30px;
    font-size: .8em;
    text-align: right;
    width: 30px;
    z-index: 9;
}

.progress_bar {
    background-color: #fff;
    border-radius: 10px;
    flex: 0 0 calc(100% - 75px);
    height: 4px;
    margin-left: 15px;
    max-width: 1000px;
    overflow: hidden;
    position: relative;
}

.filler {
    background-color: #33a474;
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    transition: all .2s ease-in-out;
}