.result_wrap_show {
	justify-content: center;
	flex-direction: column;
	align-items: center;
	display: flex;
	height: 100%;
	width: 100%;
}

.result_wrap_hide {
	display: none;
}

.result_type_wrap {
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	display: flex;
	width: 100%;
}

.caption {
	padding-bottom: 15px;
	text-align: center;
	font-size: 1.3em;
	line-height: 1.5;
	color: #51596a;
}

.type_nm {
    padding-bottom: 10px;
	font-size: 2.3em;
    font-weight: 700;
	color: #51596a;
}

/* #FF6565, #FFFFAB, #93ADDD, #77F586*/
.type_cd {
	font-size: 1.7em; 
	font-weight: 600; 
	color: #51596a; 
}

.percent_wrap {
	width: 100%;
	padding: 0;
	margin: 0;
	bottom: 0;
}

@media (min-width:500px) {
	.percent_wrap {
		padding-bottom: 20px;
		padding-top: 20px;
	}
}

.percent_bg {
	position: absolute;
	width: 100%;
	z-index: 0;
}

@media(min-width:500px) {
	.percent_bg {
		width: 500px;
	}
}

.percent_div {
	padding-right: 10px;
	padding-left: 10px;
	position: relative;
	padding-top: 13%;
	z-index: 5;
}

@media(min-width:500px) {
	.percent_div {
		padding: 20px;
	}
}

@media(min-width:400px) and (max-width:500px) {
	.percent_div {
		padding-top: 18%;
	}
}

.percent_div_inner {
	border: 1px solid #dddfe2;
	background-color: white;
	flex-direction: column;
	border-radius: 20px;
	display: flex;
	height: 100%;
}

.percent_content_y {
	border-bottom: 1px solid #dddfe2;
	padding: 10px;
}

.percent_content_n {
	padding: 10px;
}

.percent_caption {
	padding-bottom: 10px;
	text-align: center;
	color: #51596a;
	font-weight: 600;
	font-size: 1em;
}

.percent_bar_warp {
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	display: flex;
}

.percentage {
	padding-right: 10px;
	padding-left: 10px;
	color: #51596a;
	font-weight: 600;
	font-size: 1em;
}

.percent_bar {
	background-color: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    height: 10px;
}

.result_desc_wrap {
	padding-top: 30%;
	width: 100%;
}

@media (min-width:500px) {
	.result_desc_wrap {
		padding-top: 20px;
	}
}

.result_desc_inner {
	padding-right: 20px;
	padding-left: 20px;
}

.result_desc_inner p {
    font-size: 1.125em;
	line-height: 1.8;
    color: #51596a;
}

.result_desc_middle {
    clip-path: polygon(0 6%,13% 2%,65% 0,100% 5%,100% 96%,86% 100%,54% 97%,25% 100%,0 97%,0 77%);
    padding: 35px 30px 40px;
    margin-bottom: 30px;
    font-style: italic;
	font-size: 1.125em;
    line-height: 1.8;
    color: #fff;
}

.result_btn {
	padding: 20px 70px 20px 70px;
    background-color: #6c4d7b;
    border-radius: 50px;
    font-weight: 600;
    font-size: 18px;
    color: white;
}