.gnb_menu_active {
    transition: all 0.2s linear 0s;
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 10;
    opacity: 1;
}

.gnb_menu_hidden {
    transition: all 0.2s linear 0s;
    visibility: hidden;
    position: fixed;
    display: flex;
    z-index: 10;
    width: 100%;
    height: 0%;
    opacity: 0;  
}

@media (min-width:500px) {
    .gnb_menu_active {
        width: 500px;
    }

    .gnb_menu_hidden {
        width: 500px;
    }
}

.gnb_background {
    background-color: gray;
    opacity: 0.5;
    width: 20%;
}

.gnb_body {
    max-height: 100vh;
    background-color: white;
    width: 80%;
}

.gnb_body_header {
    border-bottom: 1px solid #eeeff1;
    height: 60px;
}

.gnb_exit {
    cursor: pointer;
    float: right;
    height: 60px;
}

.gnb_wrap {
    border-bottom: 1px solid #eeeff1;
    flex-direction: column;
    overflow-x: hidden;
    overflow: scroll;
    display: flex;
    height: 100%;
}

.gnb_select {
    background-color: #f5f5f5;
    transition: all 0.1s;
    padding-left: 20px;
    line-height: 50px;
    list-style: none;
    color: #4298b4;
    cursor: pointer;
    margin-top: 5px;
    font-size: 12pt;
    height: 50px;
}

.gnb_select:active {
    background: #d3d3d3;
}

.gnb_list {
    transition: all 0.1s;
    padding-left: 20px;
    line-height: 50px;
    list-style: none;
    color: #51596a;
    cursor: pointer;
    margin-top: 5px;
    font-size: 12pt;
    height: 50px;
}

.gnb_list:active {
    background: #d3d3d3;
}

.gnb_footer {
    text-align: center;
    margin-top: 5px;
    color: #eeeff1;
}

.gnb_list_type {
    padding-left: 20px;
    line-height: 50px;
    list-style: none;
    color: #51596a;
    margin-top: 5px;
    font-size: 12pt;
    height: 50px;
}