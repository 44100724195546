.wait_layer_active {
    transition: all 0.1s linear 0s;
    background-color: grey;
    visibility: visible;
    position: fixed;
    display: flex;
    z-index: 100;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}

.wait_layer_hidden {
    transition: all 0.1s linear 0s;
    visibility: hidden;
    position: fixed;
    display: flex;
    z-index: 100;
    height: 100%;
    width: 100%;
    opacity: 0;
}

.loading {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin: auto;
}


.loading img {
    animation: rotate_img 3s linear infinite;
    transform-origin: 50% 50%;
    height: 100px;
    width: 100px;
}

@media(min-width: 500px) {
    .wait_layer_active {
        width: 500px;
    }

    .wait_layer_hidden {
        width: 500px;
    }
}

@keyframes rotate_img {
    100% {
        transform: rotate(360deg);
    }
}