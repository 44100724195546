.App {
    display: flex;
    overflow: scroll;
    overflow-x: hidden;
    flex-direction: column;
}

.App::-webkit-scrollbar {
    display: none;
}

.pc-left {
    display: none;
    background-color: #f3f3f3;
    width: calc((100vw - 500px)/2);
    height: 100vh;
}

.pc-right {
    display: none;
    background-color: #f3f3f3;
    width: calc((100vw - 500px)/2);
    height: 100vh;
}

.App .navbar {
    box-shadow: 0 0 10px 0 rgb(29 36 50 / 10%);
    background-color: white;
    text-align: center;
    position: fixed;
    display: flex;
    height: 60px;
    width: 100%;
    z-index: 9;
    right: 0;
    left: 0;
    top: 0;
}

.App .navbar .logo {
    position: relative;
    height: 50px;
    width: auto;
    left: 5px;
    top: 5px;
}

.App .navbar .logo_text {
    position: relative;
    padding-top: 5px;
}

.App .navbar .menu_icon {
    margin-left: auto;
    border: 0px;
    float: right;
    height: 60px;
}

@media (min-width: 500px) {
    .App {
      width: 500px;
      height: 100vh;
    }

    .App .navbar {
        left: calc((100vw - 500px)/2);
        width: 500px;
    }

    .pc-left {
        display: block;
    }

    .pc-right {
        display: block;
    }
}

.App .content-body {
    padding-top: 60px;
}

.footer {
    background-color: #030F1F;
    flex-direction: column;
    padding-bottom: 25px;
    align-items: center;
    text-align: center;
    padding-top: 30px;
    display: flex;
    width: 100%;
}

.references {
    padding-bottom: 10px;
    color: white;
    font-size: 0.9em;
    margin: 0;
}

.ref-inner {
    padding-bottom: 20px;
}

.ref-link {
    padding-right: 10px;
    padding-left: 10px;
    color: white;
    font-size: 0.9em;
}

.developed-by {
    font-size: 0.9em;
    color: white;
    margin-top: 0;
}