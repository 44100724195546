body {
	margin: 0;
	width: 100vw;
	height: 100vh;
	font-family: Open Sans,Helvetica Neue,Arial,sans-serif!important;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: Open Sans,Helvetica Neue,Arial,sans-serif!important;
	/* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

@media(min-width:500px) {
	#root {
		display: flex;
	}
}

